import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Testimonial from "../../components/Testimonial"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import {
  animeFadeUp,
  animeSlideOutLeft,
  animeSlideOutBot,
  animeFadeIn
} from "../../utils/Animations"
import Clients from "../../components/Clients"

const ServiceActivationPage = ({data, transitionStatus}) => {
  const testimonials = [
    {
      name: 'David Ecomard, Responsable Comptes-Clés Cash & Carry - Pernod Ricard France',
      text: 'Belladrinks est un partenaire disponible, réactif, qui sait faire preuve d’agilité et d’écoute dans son organisation au quotidien. Des prestations de qualité, avec des produits de qualité, pour un très beau partenariat dans la durée'
    },
    {
      name: 'Léa Boun, Brand Communication Specialist - Pernod Ricard France',
      text: 'Pleinement conquis par une première opération avec Belladrinks, nous avons mis en place un partenariat pour des opérations de dégustations au niveau national qui fut une totale réussite.'
    },
    {
      name: 'Sébastien Brivet, Responsable régional CHR - La Martiniquais',
      text: 'Nous faisons appel à Belladrinks pour des opérations sur le terrain. C\'est une équipe très professionnelle, bien organisé et qui s’adapte à vos besoins.'
    },
    {
      name: 'Margaux Estour, Ambassadrice Suze – Pernod Ricard France',
      text: `J’ai pu avoir la pleine confiance de leur laisser l’autonomie sur un gros projet de la marque Suze. C’est pour moi une équipe professionnelle, réactive, et surtout enthousiaste avec laquelle il est agréable de travailler`
    }
  ]
  return (
    <Layout>
      <SEO
        title="Activation de marque - Belladrinks bar à cocktails événementiel"
        description="Belladrinks met à disposition le savoir-faire de son équipe auprès des acteurs de l’industrie des spiritueux."
      />
      <PageHeader data={data} transitionStatus={transitionStatus} title={"Activation de marque"}/>
      <section className="py-16 md:py-40 2xl:py-56 2xl:pb-48">
        <InView triggerOnce={true} threshold={0.5} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
              className="main-container grid grid-cols-1 xl:grid-cols-2 lg:gap-4" ref={ref}
            >
              <div>
                <motion.h2 variants={animeFadeUp} className="display-1 mb-8 pr-10">Un savoir-faire unique au service des professionnels.</motion.h2>
                <motion.div
                  variants={{
                    hidden:{
                      width: 0
                    },
                    visible: {
                      width: '7rem',
                      transition: {
                        ease: "easeOut",
                        duration: "0.6"
                      }
                    }
                  }}
                  className="border-b-4 border-copper mt-2 mb-8"
                />
              </div>
              <div>
                <motion.p variants={animeFadeUp} className="lead">
                  Belladrinks met à disposition le savoir-faire de son équipe auprès des acteurs de l’industrie des spiritueux et des boissons pour valoriser leur image de marque et mettre en avant leurs produits sur le terrain.
                  De la planification jusqu’à l'exécution et l'engagement des consommateurs dans l'environnement en direct, nous sommes présents à chaque instant.
                  Cela signifie pour nos clients une solution à leurs problèmes de personnel, de logistique et de budget.
                </motion.p>
              </div>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="main-container pb-40">
        <div className="grid grid-cols-2 gap-2 lg:grid-rows-2 lg:grid-flow-row lg:auto-rows-fr">
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto lg:row-span-2 overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img1.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img2.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                className="bg-white py-10 px-8 md:p-16 md:py-20 2xl:p-20 2xl:py-32 col-span-2 lg:col-auto"
              >
                <motion.h2 variants={animeFadeUp} className="display-2 mb-6">Une équipe expérimentée</motion.h2>
                <motion.p variants={animeFadeUp} className="lead">
                  Nous accompagnons depuis plus de 10 ans les marques dans la mise en avant de leurs produits sur le terrain. Nous portons un soins tout particulier à la formation de notre personnel pour communiquer le message de nos clients à leurs consommateurs de manière transparente.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto overflow-hidden col-span-2 ">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img3.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                className="bg-white py-10 px-8 md:p-16 md:py-20 2xl:p-20 2xl:py-32 col-span-2 lg:col-auto"
              >
                <motion.h2 variants={animeFadeUp} className="display-2 mb-6">Nos compétences</motion.h2>
                <motion.ul variants={animeFadeUp} className="lead">
                  <li>Formation produit</li>
                  <li>Activation de marque (GSA, salon, CHR)</li>
                  <li>Transport classique et réfrigéré</li>
                  <li>Entreposage</li>
                  <li>Régisseur</li>
                  <li>Consulting</li>
                </motion.ul>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 col-span-2 lg:col-auto lg:h-auto overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutBot} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img5.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="flex justify-end mt-20">
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                initial={false}
                animate={inView ? "visible" : "hidden"}
                variants={animeFadeUp}
              >
                <TransitionLink
                  className="link"
                  to="/contact/"
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  Demandez un devis
                </TransitionLink>
              </motion.div>
            )}
          </InView>
        </div>
      </section>
      <section className="relative py-20 md:py-36 lg:py-40 2xl:py-56 bg-bigstone-light">
        <InView triggerOnce={true} threshold={0.25} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              className="main-container"
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
            >
              <motion.h3 variants={animeFadeUp} className="display-1 mb-8 text-white">Ils nous font confiance</motion.h3>
              <motion.div variants={animeFadeIn}>
                <Testimonial testimonials={testimonials}/>
              </motion.div>
              <Clients/>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="py-20 md:py-36 lg:py-40 2xl:py-52">
        <InView triggerOnce={true} threshold={0.25} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              animate={inView ? "visible" : "hidden"}
              className="main-container text-center"
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.2 }
                }
              }}
            >
              <motion.h2 variants={animeFadeIn} className="display-1">Vous avez un projet ?</motion.h2>
              <motion.p variants={animeFadeIn} className="lead mb-8">Contactez notre équipe et obtenez un devis rapidement.</motion.p>
              <motion.div variants={animeFadeIn}>
                <TransitionLink
                  className="button bg-copper text-white hover:bg-white hover:text-copper mr-2 md:mr-8"
                  to="/contact/"
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  Contactez-nous
                </TransitionLink>
              </motion.div>
            </motion.div>
          )}
        </InView>
      </section>
    </Layout>
  )
}

export default ServiceActivationPage

export const query = graphql`
    query {
        backgroundHeader: file(relativePath: { eq: "services/activation/stanislav-ivanitskiy-ct1uNvddgMk-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(relativePath: { eq: "services/activation/licor-beirao-FQsOHvudS8E-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(relativePath: { eq: "services/activation/IMG_20191021_162525.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(relativePath: { eq: "services/activation/redbull_salonproxi.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img5: file(relativePath: { eq: "services/activation/IMG_20190922_194200.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`